header
	@include fullPageGrid
	grid-template-rows: 20fr 1fr
	grid-template-areas: "dots main main" "dots nav nav"
	position: relative
	height: 80vh
	&:before
		content: ""
		position: absolute
		z-index: -1
		background: $guideLines
		width: calc(100% + 2*$windowPadding)
		height: 1px
		left: calc(-1 * $windowPadding)
		bottom: 0
	& > *
		// @include devOutline
	.dots-grid
		padding: 8px
		margin-right: $windowPadding
		grid-area: dots
		display: grid
		grid-template-columns: repeat(25, 1fr)
		align-items: center
		justify-items: center
		position: relative
		&:after
			content: ""
			position: absolute
			z-index: -1
			background: $guideLines
			width: 1px
			height: calc(100% + $windowPadding)
			right: 0px
			top: calc(-1 * $windowPadding)
		span
			width: 3px
			height: 3px
			border-radius: 50%
			background: $grey
			@include transition(3)
			&.glow
				background: #91dfff
				box-shadow: 0px 0px 20px 6px $main
	main
		grid-area: main
		display: flex
		flex-direction: column
		justify-content: center
		position: relative
		&:before
			content: ""
			position: absolute
			z-index: -1
			background: $guideLines
			width: 1px
			height: calc(40px + 100% + $windowPadding)
			left: 0px
			top: calc(-1 * $windowPadding)
	h1
		font-size: 72px
		text-transform: uppercase
		font-weight: 700
		letter-spacing: 2px
		position: relative
		left: -6px
		@include textGradient
		&:after
			content: ""
			position: absolute
			z-index: -1
			background: $guideLines
			width: calc($windowPadding + 100% + $windowPadding)
			height: 1px
			bottom: 9px
			left: calc(-1 * $windowPadding + 6px)
	h2
		font-size: 32px
		letter-spacing: 1px
		text-transform: uppercase
		margin: 16px 0 32px
		position: relative
		line-height: 1.2
		&:before
			content: ""
			position: absolute
			z-index: -1
			background: $guideLines
			width: calc($windowPadding + 100% + $windowPadding)
			height: 1px
			bottom: 4px
			left: calc(-1 * $windowPadding)
	.text
		font-size: 15px
		max-width: 620px
		p + p
			margin-top: 16px
nav
	padding: 12px 0
	grid-area: nav
	display: flex
	justify-content: space-between
	border-left: 1px solid $guideLines
	ul
		display: flex
		gap: 50px
		li
			border-bottom: 2px solid $white
			padding: 0 36px 8px 0
			font-weight: 700
			font-size: 16px
			letter-spacing: 1px
			text-transform: uppercase
	.phone-number
		margin-right: 24px
		span
			color: $grey
		i
			color: $white
			@include transition
		&:hover i
			@include textGradient
