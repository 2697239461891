.career-wrapper
    @include fullPageGrid
    .career
        @include asideCol
        padding: calc($windowPadding / 2)
        h3
            font-size: 30px
            font-weight: 300
            letter-spacing: 5px
            margin-top: calc($windowPadding * 2)
            line-height: 1.3
            margin-bottom: 32px
            text-transform: uppercase
        .chapeau
            font-weight: 600
        p + p
            margin-top: 16px
    .career-timeline
        @include mainContentCol
        padding-top: calc($windowPadding * 2)
        .career-content
            display: grid
            align-items: center
            grid-template-columns: repeat(16, 1fr)
            grid-template-rows: auto 1fr 1fr
            text-align: center
            position: relative
            $careerContentCol: calc(100% / 8)
            &::after
                content: ""
                position: absolute
                background: linear-gradient(270deg, #15303f 18.39%, #02B2D9 80.77%)
                width: 100%
                height: 2px
                top: 10px
                z-index: -1
                border-radius: $radius-m
            .time-col
                grid-column: span 2
                p
                    background: $background
                    display: inline-block
                    padding: 0 8px
                &.out
                    color: #637489
            .career-exp
                position: relative
                &:before, &:after
                    content: ""
                    position: absolute
                    width: 10px
                    height: 10px
                    border-radius: 50%
                    background: $background
                    border: 2px solid $main
                    top: 50%
                    transform: translateY(-50%) translateX(-50%)
                    left: 0
                &:after
                    transform: translateY(-50%) translateX(+50%)
                    left: auto
                    right: 0
                p
                    position: relative
                    &:before
                        content: ""
                        position: absolute
                        width: 100%
                        height: 1px
                        top: 50%
                        left: 0
                        transform: translateY(-50%)
                        border-bottom: 2px dashed $main
                        z-index: -1
                    span
                        max-width: 120px
                        background: $background
                        display: inline-block
                        padding: 0 8px
            .asf
                grid-column: 2 / 4
                grid-row: 2
                span
                    margin-top: 22px
            .beyable
                grid-column: 5 / 14
                grid-row: 2
            .freelance
                grid-column: 2 / 14
                grid-row: 3
            .career-study
                grid-column: 14 / 16
                grid-row: 2 / 4
                text-align: left
                padding-left: 16px
            .timeline-v
                height: 100%
                width: 100%
                grid-column: 2 / 14
                grid-row: 2 / 3
                position: relative
                &::before
                    content: ""
                    position: absolute
                    width: calc(100% - 2px)
                    height: 150%
                    top: 0
                    left: -1px
                    border-left: 2px dashed $main
                    border-right: 2px dashed $main
                    z-index: -1
                &.half
                    grid-column: 4 / 5
                    grid-row: 2
                    &::before
                        height: 50%
