.projects-inner
	position: relative
.subnav-container
	position: absolute
	width: $windowPadding
	right: 100%
	height: 100%
	margin-top: 75px
	text-align: center
.subnav
	position: sticky
	top: 25px
	color: $grey
	button:disabled
		opacity: .5
	svg
		color: $grey
		@include transition
		&:hover
			color: $white
	p
		letter-spacing: 3px
.project
	@include fullPageGrid
	.project-infos
		@include asideCol
		text-transform: uppercase
		letter-spacing: 1px
		.project-infos-inner
			padding: calc($windowPadding / 2)
			position: sticky
			top: 0px
			margin-top: calc($windowPadding * 2)
		.project-type
			color: $grey
			font-weight: 300
			font-size: 26px
			span
				font-size: 14px
				display: inline-block
				margin-left: 8px
		.project-name
			font-size: 30px
			font-weight: 300
			letter-spacing: 5px
			margin-top: 8px
			line-height: 1.3
		.project-title
			font-weight: 300
			font-size: 18px
			line-height: 100%
			color: $grey
			margin: 36px 0 12px
		.project-challenges
			p
				text-transform: none
				letter-spacing: 0
			p + p
				margin-top: 16px
		.project-skills
			text-transform: none
			letter-spacing: 0
			display: flex
			flex-wrap: wrap
			column-gap: 4px
			row-gap: 12px
			li
				text-align: center
				width: 54px
				img
					max-width: 100%
				p
					font-size: 10px
	.project-wrapper
		@include mainContentCol
		.project-grid
			position: absolute
			z-index: -2
			width: 100%
			height: 100%
			display: grid
			grid-template-columns: repeat(6, 1fr)
			column-gap: 20px
			span
				width: 100%
				height: 100%
				background: $col
			& + .project-missions
				margin-top: calc($windowPadding * 2)
		.project-missions
			position: relative
			display: grid
			grid-template-columns: repeat(2, 1fr)
			grid-gap: 20px
			margin-bottom: 50px
			&:before
				content: ""
				position: absolute
				z-index: -1
				width: calc(100% - $windowPadding)
				height: calc(100% - 1.5 * $windowPadding)
				top: calc($windowPadding / 2)
				left: calc($windowPadding / 2)
				border-radius: $radius-m
				border: 1px solid $main
			.mission
				padding: 50px
				&.top
					padding-top: calc($windowPadding * 2)
				&.left
					padding-left: calc($windowPadding * 2)
				&.bottom
					margin-bottom: $windowPadding
				.title
					font-size: 18px
					font-weight: 700
					margin-bottom: 12px
				.detail
					line-height: 1.5
					& + .detail
						margin-top: 12px
					.star
						color: #E9CE45
						letter-spacing: 4px
				.detail li:before
					content: "• "
			&.beyable-identity
				grid-template-columns: repeat(6, 1fr)
				section:nth-of-type(1)
					grid-column: 1 / span 3
				section:nth-of-type(2)
					grid-column: 1 / span 2
				section:nth-of-type(3)
					grid-column: 4 / span 3
					grid-row: 1 / 4
			&.beyable-scenario
				grid-template-columns: repeat(6, 1fr)
				grid-template-rows: repeat(4, auto)
				align-items: start
				grid-template-areas: 'cell-1 cell-1 cell-1 cell-2 cell-3 cell-3' 'cell-4 cell-5 cell-5 cell-6 cell-6 cell-6' 'cell-4 cell-5 cell-5 cell-8 cell-8 cell-9' 'cell-4 cell-7 cell-7 cell-8 cell-8 cell-9'
				figure + figure
					margin: 0
				@mixin BeyGrid
					display: grid
					gap: 20px
					align-items: start
					@media screen and (max-width: 540px)
						gap: 12px
				.project-stats
					div
						margin-top: 16px
						text-align: center
						p
							font-size: 35px
							font-weight: 700
						span
							text-transform: uppercase
							@include textGradient
				.cell-1
					grid-area: cell-1
				.cell-2
					grid-area: cell-2
					height: 100%
					display: flex
					flex-direction: column
					justify-content: center
				.cell-3
					grid-area: cell-3
					@include BeyGrid
					grid-template-columns: repeat(2, 1fr)
					align-items: end
					height: 100%
				.cell-4
					grid-area: cell-4
					@include BeyGrid
					grid-template-rows: auto auto
				.cell-5
					grid-area: cell-5
					@include BeyGrid
					grid-template-columns: repeat(2, 1fr)
					figure:nth-of-type(2)
						grid-column: 2 / 3
						grid-row: 1 / 3
				.cell-6
					grid-area: cell-6
					@include BeyGrid
					grid-template-columns: repeat(3, 1fr)
					figure:nth-of-type(2)
						grid-column: 2 / 4
				.cell-7
					grid-area: cell-7
				.cell-8
					grid-area: cell-8
					@include BeyGrid
					grid-template-rows: repeat(2, 1fr)
				.cell-9
					grid-area: cell-9
					@include BeyGrid
					grid-template-rows: repeat(2, 1fr)
			&.mainstream
				grid-template-columns: repeat(6, 1fr)
				section:nth-of-type(1)
					grid-column: 1/span 2
					grid-row: 1 / 1
				section:nth-of-type(2)
					grid-column: 2
					grid-row: 2/4
				section:nth-of-type(3)
					grid-column: 3
					grid-row: 1/ 6
				section:nth-of-type(4)
					grid-column: 4
					grid-row: 1/6
				section:nth-of-type(5)
					grid-column: 5
					grid-row: 1/3
					padding-top: 75px
				figure img
					filter: drop-shadow(0px 0px 7px rgba(6, 121, 168, 0.8))
			&.daily-ui
				align-items: start
				section
					display: grid
					grid-template-columns: repeat(3, 1fr)
					grid-gap: 20px
					&:nth-of-type(1)
						figure:nth-of-type(1)
							grid-column: 1/5
							margin-top: 50px
						figure:nth-of-type(2)
							grid-column: 2/span 3
					&:nth-of-type(2)
						figure:nth-of-type(1)
							grid-column: 1/span 2
						figure:nth-of-type(2)
							grid-column: 1/span 3
						figure:nth-of-type(3)
							grid-column: 1/span 2
			figure
				display: flex
				flex-direction: column
				align-items: start
				& + figure
					margin-top: 25px
				img
					width: 100%
					border-radius: $radius-m
				figcaption
					position: relative
					display: inline-flex
					padding: 8px 0 4px
					margin: 0
					background: $col
					&:before
						content: ""
						display: block
						margin: 2px 8px 0 0
						width: 21px
						min-width: 21px
						height: 12px
						background: $main
						border-bottom-right-radius: 50px
