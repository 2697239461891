:root
	--g-space: 48px
$windowPadding: var(--g-space)
$background: #17202B
$col: #1F2833
$guideLines: #395785
$main: #0679A8
$stroke: #1485B3
$grey: #84898F
$white: #FFFFFF
$gradient: linear-gradient(280deg, #1874A7 0%, #11C5F6 100%)
$radius: 10px
$radius-m: 5px
$radius-s: 2px

@mixin textGradient
	background: $gradient
	-webkit-background-clip: text
	-webkit-text-fill-color: transparent
@mixin transition($time: 2)
	transition: all #{$time/10}s ease-in-out
@mixin devOutline
	outline: 1px solid yellow
@mixin asideCol
	margin-right: $windowPadding
	position: relative
	&:after
		content: ""
		position: absolute
		z-index: -1
		background: $guideLines
		width: 1px
		height: calc(100% + $windowPadding)
		right: 0px
		top: 0
@mixin mainContentCol
	position: relative
	&:before
		content: ""
		position: absolute
		z-index: -1
		background: $guideLines
		width: 1px
		height: calc(100% + $windowPadding)
		left: 0px
		top: 0
@mixin fullPageGrid
	display: grid
	grid-template-columns: 1fr 3fr
