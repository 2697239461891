@import "./Styles/Global.sass"

body
	font-family: Montserrat, Roboto, Arial, sans-serif
	font-size: 16px
	background: $background
	color: $white
	padding: $windowPadding
p
	line-height: 1.5

.guide
	background: $guideLines
	position: absolute
	&.vertical
		width: 1px
		position: fixed
		top: 0
		bottom: 0
	&.horizontal
		width: 100%
		height: 1px
		left: 0
		right: 0
	&.left
		left: $windowPadding
	&.right
		right: $windowPadding
	&.top
		top: $windowPadding
.nospace
	white-space: nowrap

@import "./Styles/Header.sass"
@import "./Styles/Projects.sass"
@import "./Styles/Career.sass"
@import "./Styles/Media.sass"