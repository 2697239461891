@media screen and (min-height: 1280px) and (max-width: 720px)
    header
        height: 50vh
@media screen and (max-width: 1366px)
    :root
        --g-space: 24px
    body
        font-size: 14px
    .project
        .project-infos
            .project-type,
            .project-title
                font-size: 16px
            .project-type span
                display: block
                margin: 0
            .project-name
                font-size: 24px
        .project-wrapper
            .project-missions
                &:before
                    height: calc(100% - 2 * $windowPadding)
                .mission
                    padding: 30px
                    .title
                        font-size: 14px
                &.mainstream
                    section:nth-of-type(2)
                        grid-column: 3
                        grid-row: 1/3
                        padding-top: 75px
                    section:nth-of-type(3)
                        grid-column: 4
                    section:nth-of-type(4)
                        grid-column: 5
                    section:nth-of-type(5)
                        grid-column: 6
                &.beyable-scenario .project-stats div 
                    margin-top: 0
                    margin-bottom: 12px
                    p
                        font-size: 24px

@media screen and (max-width: 1024px)
    body
        font-size: 12px
    header
        h1
            font-size: 56px
            left: -4px
        h2
            font-size: 24px
    nav ul li
        font-size: 12px
    .project .project-infos .project-title
        margin: 24px 0 8px 0px
    .career-wrapper .career h3
        font-size: 24px
        margin-bottom: 24px

@media screen and (max-width: 720px)
    .guide.right
        right: calc(var(--g-space) + 1px)
    header
        display: flex
        flex-direction: column
        .dots-grid
            width: 100%
            margin-right: 0
            display: flex
            flex-wrap: wrap
            align-items: center
            justify-content: center
            padding: 5px
            max-height: 15vh
            overflow: hidden
            span
                margin: 4px
            &:after
                display: none
        main
            height: 100%
            &:before
                display: none
    .project
        display: block
        .project-infos:after,
        .project-wrapper:before
            display: none
        .project-infos
            .project-infos-inner
                padding: $windowPadding
            .project-type span
                margin-left: 8px
                display: inline-block
        .project-wrapper
            .project-missions
                display: block
                margin-bottom: $windowPadding
                padding-bottom: $windowPadding
            &:after
                content: ""
                position: absolute
                z-index: -1
                background: #395785
                width: calc(var(--g-space) + 100% + var(--g-space))
                height: 1px
                bottom: 0
                left: calc(-1 * var(--g-space))
            .project-grid
                grid-template-columns: repeat(4, 1fr)
                gap: 12px
                span:nth-of-type(5),
                span:nth-of-type(6)
                    display: none
                & + .project-missions
                    margin-top: $windowPadding
            .project-missions .mission,
            .project-missions .mission.left,
            .project-missions .mission.top,
            .project-missions .mission.bottom
                padding: $windowPadding
            .project-missions
                &:before
                    display: none
                figure
                    flex-direction: column-reverse
                    margin-top: $windowPadding
                    figcaption
                        padding: 0px 0 4px
                        &:before
                            margin-top: 0
                            border-bottom-right-radius: 0
                            border-top-right-radius: 50px
                &.beyable-scenario
                    display: grid
                    gap: 12px
                    grid-template-columns: repeat(4, 1fr)
                    grid-template-areas: "cell-1 cell-1 cell-1 cell-1" "cell-2 cell-2 cell-2 cell-2" "cell-3 cell-3 cell-3 cell-3" "cell-4 cell-4 cell-9 cell-9" "cell-7 cell-7 cell-7 cell-7" "cell-5 cell-5 cell-5 cell-5" "cell-8 cell-8 cell-8 cell-8" "cell-6 cell-6 cell-6 cell-6"
                    .cell-2
                        flex-direction: row
                        justify-content: space-around
                    .cell-3
                        align-items: start
                    figure
                        margin: 0
                &.daily-ui
                    section
                        grid-template-columns: repeat(4, 1fr)
                        gap: 12px
                        align-items: start
                        &:nth-of-type(2)
                            figure:nth-of-type(1)
                                grid-column: 1/span 2
                                grid-row: 1
                            figure:nth-of-type(2)
                                grid-column: 1/span 4
                                grid-row: 2
                            figure:nth-of-type(3)
                                grid-column: 3/span 2
                                grid-row: 1
                &.mainstream
                    display: grid
                    grid-template-columns: repeat(4, 1fr)
                    gap: 12px
                    section
                        figure
                            margin-top: 0
                        &:nth-of-type(1)
                            grid-column: 1 / span 4
                            grid-row: auto
                        &:nth-of-type(2)
                            padding-top: 0
                            grid-column: 2 / span 2
                            grid-row: auto
                        &:nth-of-type(3)
                            grid-column: 1 / span 2
                            grid-row: auto
                        &:nth-of-type(4)
                            grid-column: 3 / span 2
                            grid-row: auto
                        &:nth-of-type(5)
                            grid-column: 2 / span 2
                            grid-row: auto
                            padding: 0

    .career-wrapper
        display: block
        .career:after
            display: none
        .career-timeline:before
            display: none
@media screen and (max-width: 540px)
    .career-wrapper .career-timeline .career-content
        grid-template-columns: repeat(14, 1fr)
        .career-study
            display: none
        .career-exp p span
            max-width: 40px
            padding: 0 4px